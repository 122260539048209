<template>
  <div class="similar column is-narrow scrollarrows is-hidden-mobile" style="display: flex; align-items: center;">
    <font-awesome-icon
      @click="scroll"
      :icon="iconDirection"
      class="fa-2x"
      style="margin-left: 0.3rem;"
    ></font-awesome-icon>
  </div>
</template>

<script>
export default {
  name: "scrollarrow",
  props: ["direction", "elementid"],
  methods: {
    scroll() {
      let target = document.getElementById(this.elementid);
      this.direction == "right"
        ? target.scrollBy({
            top: 0,
            left: 300,
            behavior: "smooth"
          })
        : target.scrollBy({
            top: 0,
            left: -300,
            behavior: "smooth"
          });
    }
  },
  computed: {
    iconDirection() {
      return this.direction == "left" ? "chevron-left" : "chevron-right";
    }
  }
};
</script>

<style scoped>
</style>