<template>
  <div :id="randomid" class="similar column is-10-desktop is-12-mobile scrollmenu">
    <slot />
  </div>
</template>

<script>

export default {
  name: "scroller",
  props: ["randomid"],
  watch: {
    randomid() {
      //id = this.randomid;
    }
  },
  mounted() {
    //id = this.randomid;
  }
};

// console.log(id);
</script>

<style scoped>
.scrollmenu {
  flex: auto !important;
  overflow: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

.scrollmenu::-webkit-scrollbar {
  display: none;
}

.scrollmenu img {
  margin-left: 0.5em;
  margin-right: 0.5em;
  max-width: 13rem;
  max-height: 13rem;
  border-radius: 5px;
}

.scrollarrows {
  display: flex;
  align-items: center;
  width: min-content;
}
</style>