<template>
  <div class="columns is-mobile">
    <scrollarrow
      :elementid="id"
      @hideArrowsOnReviewCard="showArrows = $event"
      v-show="showArrows"
      direction="left"
    ></scrollarrow>
    <scroller :randomid="id">
      <div class="columns is-mobile">
        <slot />
      </div>
    </scroller>
    <scrollarrow :elementid="id" v-show="showArrows" direction="right"></scrollarrow>
  </div>
</template>

<script>
import Scroller from "@/components/Sidescroll/Scroller.vue";
import Scrollarrow from "@/components/Sidescroll/Scrollarrow.vue";

import { Bus } from "@/utils/Bus.js";

export default {
  name: "scrollmenu",
  props: ["showArrows"],
  components: {
    Scroller,
    Scrollarrow
  },
  methods: {
    randomId() {
      var array = new Uint32Array(1);
      window.crypto.getRandomValues(array);
      var id = "scrollmenu" + array.find(Number);
      return id;
    },
    fixarrows() {
      let menu = document.getElementById("scrollmenu1314417492");
      menu.scrollWidth > menu.offsetWidth
        ? (this.showArrows = true)
        : (this.showArrows = false);
    },
    scrollToBeginning() {
      document.getElementById(this.id) != null
        ? document.getElementById(this.id).scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          })
        : {};
    }
  },
  computed: {
    id() {
      return this.randomId();
    }
  },
  created() {
    Bus.$on("scroll", this.scrollToBeginning);
  }
};
</script>

